<!--  -->
<template>
  <div class="class-grade-content">
    <div class="create-btns">
      <!-- 班级报告 -->
      <el-button
        v-if="isGenerateClassReportVisible"
        class="create-report"
        :loading="buttonLoadingList[0].loading"
        :disabled="buttonLoadingList[0].disabled"
        @click="createClassOrGradeReport('class-generating', 3, 0)"
      >
        生成群体报告
      </el-button>
      <el-button
        v-if="isGeneratingClassReportVisible"
        class="create-report"
        :loading="buttonLoadingList[2].loading"
        :disabled="buttonLoadingList[2].disabled"
      >
        正在生成群体报告
      </el-button>
    </div>
  </div>
</template>

<script>
import { fileDownloadByUrl } from "@/core/util/util";
import { generate, getReportUrl } from "@/core/api/analy/analyreportsubject";
//
export default {
  props: {
    classAndGradeData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      //班级/年级报告
      selectedClassesItems: [],
      allClassItems: [],

      //报告生成按钮组--禁用，加载
      buttonLoadingList: [
        {
          loading: false,
          disabled: false,
          name: "class-generate",
        },
        {
          loading: false,
          disabled: false,
          name: "class-regenerate",
        },
        {
          loading: false,
          disabled: false,
          name: "class-generating",
        },
        {
          loading: false,
          disabled: false,
          name: "class-download",
        },
      ],
      //报告生成按钮组--显示
      //班级报告
      isGenerateClassReportVisible: false,
      isGeneratingClassReportVisible: false,
      isReGenerateClassReportVisible: false,
      isDownloadClassReportVisible: false,
    };
  },
  watch: {
    classAndGradeData() {
      this.selectedClassesItems = this.allClassItems;
      this.refreshPageToGetBasicData();
      return this.classAndGradeData;
    },
  },
  mounted() {
    this.refreshPageToGetBasicData();
  },
  methods: {
    showChangeData() {
      // console.log(this.selectedClassesItems);
    },
    //刷新页面数据
    refreshPageToGetBasicData() {
      //班级数据
      if (
        this.classAndGradeData.classNames &&
        this.classAndGradeData.classNames.indexOf(",") > -1
      ) {
        this.allClassItems = this.classAndGradeData.classNames.split(",");
      } else {
        this.allClassItems = [this.classAndGradeData.classNames];
      }
      this.selectedClassesItems = this.allClassItems;
      // console.log(this.allClassItems);
      // 初始化报告按钮状态
      this.refreshReportButtonVisible();
    },
    //更新按钮组状态--显示
    refreshReportButtonVisible() {
      // 班级报告相关
      this.isGenerateClassReportVisible = false;
      this.isReGenerateClassReportVisible = false;
      this.isGeneratingClassReportVisible = false;
      this.isDownloadClassReportVisible = false;
      let classReportURL = this.classAndGradeData.classtUrl;
      if (classReportURL && classReportURL == 1) {
        this.isGeneratingClassReportVisible = true;
        this.startGetReportUrl(1, 0, 0);
      } else if (classReportURL && classReportURL.indexOf("http") == 0) {
        this.isDownloadClassReportVisible = true;
        this.isReGenerateClassReportVisible = true;
      } else {
        this.isGenerateClassReportVisible = true;
      }
    },
    //再次更新按钮组状态--加载/禁用
    refreshReportBtnsLoadingAndDisabled(name) {
      this.buttonLoadingList.map((item) => {
        item.disabled = true; //所有按钮禁用
        item.loading = false;
        if (item.name == name) {
          item.disabled = false;
          item.loading = true;
        }
        if (!name) {
          item.disabled = false;
          item.loading = false;
        }
      });
    },
    /**
     * 生成班级/年级报告
     * name: 按钮标识 -preview:预览 -
     * type: 1-班级报告 2-年级报告
     * isPreview: 0-生成 1-预览(班级/年级报告没有预览)
     */
    createClassOrGradeReport(name, type, preview) {
      //type: 1-班级报告 2-年级报告
      this.generateReport(name, type, preview);
    },
    setButtonStatus(type, name, index) {
      //index: 0-生成 1-下载/重新生成 2-正在生成
      this.refreshReportBtnsLoadingAndDisabled(name);
      if (index == 0) {
        this.isGenerateClassReportVisible = true;
        this.isReGenerateClassReportVisible = false;
        this.isGeneratingClassReportVisible = false;
        this.isDownloadClassReportVisible = false;
      }
      if (index == 1) {
        this.isGenerateClassReportVisible = false;
        this.isReGenerateClassReportVisible = true;
        this.isGeneratingClassReportVisible = false;
        this.isDownloadClassReportVisible = true;
      }
      if (index == 2) {
        this.isGenerateClassReportVisible = false;
        this.isReGenerateClassReportVisible = false;
        this.isGeneratingClassReportVisible = true;
        this.isDownloadClassReportVisible = false;
      }
    },
    // 生成班级/年级报告报告
    generateReport(name, type, isPreview) {
      this.setButtonStatus(type, name, 2);
      let classIds = this.selectedClassesItems.toString();
      generate(
        Object.assign({
          isPreview: 0,
          reportType: type || 0,
          reportSubjectId: this.$route.query.id,
          classNums: classIds,
        })
      )
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.data) {
              if (res.data.data.indexOf("http") == 0) {
                this.setButtonStatus(type, "", 0);
                fileDownloadByUrl(res.data.data);
              } else {
                this.setButtonStatus(type, name, 2);
                this.startGetReportUrl(type, isPreview);
              }
            } else {
              // this.$message.error("生成报告失败...");
            }
          } else {
            // this.$message.error("生成报告失败了");
          }
        })
        .catch(() => {
          // this.$message.error(err);
          this.setButtonStatus(type, "", 0);
          this.refreshReportBtnsLoadingAndDisabled();
        });
    },
    startGetReportUrl(type, isPreview) {
      let thisObj = this;
      setTimeout(function () {
        thisObj.sendGetReportUrl(type, isPreview);
      }, 3000);
    },
    sendGetReportUrl(type, isPreview) {
      let classIds = this.selectedClassesItems.toString();
      let getURLQuery = {
        reportSubjectId: this.$route.query.id,
        reportType: type,
        isPreview: isPreview,
        classNums: classIds,
      };
      getReportUrl(getURLQuery)
        .then((data) => {
          let currentUrl = data.data.data;
          if (currentUrl == "") {
            //空字符串，轮询
            if (type == 1) {
              this.setButtonStatus(type, "class-generating", 2);
            } else {
              this.setButtonStatus(type, "grade-generating", 2);
            }
            this.startGetReportUrl(type, isPreview);
          } else if (currentUrl) {
            if (currentUrl == 1) {
              // if (type == 1) {
              //   this.setButtonStatus(type, "class-generating", 2);
              // } else {
              //   this.setButtonStatus(type, "grade-generating", 2);
              // }
              // this.startGetReportUrl(type, isPreview);
            } else if (currentUrl.indexOf("http") == 0) {
              //http开头,报告生成成功
              this.setButtonStatus(type, "", 0);
              fileDownloadByUrl(currentUrl);
            } else if (currentUrl.indexOf("/") > -1) {
              //进度比展示，表示生成途中，轮询
              if (type == 1) {
                this.setButtonStatus(type, "class-generating", 2);
              } else {
                this.setButtonStatus(type, "grade-generating", 2);
              }
              this.startGetReportUrl(type, isPreview);
            } else {
              // this.$message.error("报告生成失败了...");
              this.setButtonStatus(type, "", 0);
            }
          } else {
            // this.$message.error("报告生成失败了...");
            this.setButtonStatus(type, "", 0);
          }
        })
        .catch(() => {
          // this.$message.error(err);
          this.setButtonStatus(type, "", 0);
        });
    },
    downloadReport(type) {
      if (type == 1) {
        if (
          this.currentReportSubject.classUrl &&
          this.currentReportSubject.classUrl.indexOf("http") == 0
        ) {
          fileDownloadByUrl(this.currentReportSubject.classUrl);
        }
        // 如果无法下载，说明有问题，刷新按钮
        else {
          this.refreshReportButtonVisible();
        }
      } else if (type == 2) {
        if (
          this.currentReportSubject.gradeUrl &&
          this.currentReportSubject.gradeUrl.indexOf("http") == 0
        ) {
          fileDownloadByUrl(this.currentReportSubject.gradeUrl);
        }
        // 如果无法下载，说明有问题，刷新按钮
        else {
          this.refreshReportButtonVisible();
        }
      }
    },
  },
};
</script>
<style lang="scss">
.class-grade-content {
  min-height: 100px;
  padding: 10px;
  border-top: 2px solid rgba(0, 0, 0, 0.05);
  .el-checkbox-group {
    display: inline;
  }
  .create-btns {
    .create-report {
      padding: 10px 16px;
      border-radius: 4px;
      background-color: #2474ed;
      color: #ffffff;
      cursor: pointer;
      margin: 10px;
      i {
        color: #ffffff;
      }
    }
  }
}
</style>
