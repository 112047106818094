<template>
  <div v-loading="fullscreenLoading" class="student-report">
    <div class="report-title">
      <div class="page-title">
        <WTGoBack default-u-r-l="/analy/report" />
        <span>{{ reportName }}</span>
      </div>
      <div class="report-subject">
        <div class="subject-name">
          <el-select
            v-model="currentReportSubjectName"
            placeholder=""
            class="subject-select"
            @change="subjectSwitching"
          >
            <el-option
              v-for="item in reportSubjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="report-header">
      <div v-if="showReportTips" class="report-tips" @click="closeReportTips">
        <div class="close-btn">
          <img src="@/assets/imgs/analy/icon-close.png" alt="" />
        </div>
        <div class="tips">
          <div class="tips-icon">
            <img src="@/assets/imgs/facial.png" alt="" />
          </div>
          <div class="tips-text">
            <div>小贴士</div>
            <div>
              精准教学可根据每个学生的考试情况，针对性的生成个性化的练习。
            </div>
          </div>
        </div>
      </div>
      <div class="report-tab">
        <div class="page-name">报告生产</div>
        <div class="page-btns">
          <div
            v-for="(val, key) in functionBtns"
            :key="key"
            :class="{ 'current-btn': val.lable == currentReportTab }"
            @click="tabHandleClick(val.lable, key)"
          >
            {{ val.value }}
          </div>
        </div>
      </div>
    </div>
    <!-- 学生报告/成绩 -->
    <div
      v-if="isActiveContent == 4 || isActiveContent == 2"
      class="report-score-content"
    >
      <div v-if="isActiveContent == 4" class="content-head">
        <div class="report-screening">
          <div class="search-label">筛选</div>
          <div class="search-items">
            <div class="search-item">
              <span>姓名</span>
              <el-input
                v-model="searchItemsForm.name"
                @keyup.enter.native="searchStudent"
                @change="resetQueryData"
              >
              </el-input>
            </div>
            <div class="search-item">
              <span>学籍号</span>
              <el-input
                v-model="searchItemsForm.studentNo"
                @keyup.enter.native="searchStudent"
                @change="resetQueryData"
              >
              </el-input>
            </div>
            <div class="search-item">
              <span>考号</span>
              <el-input
                v-model="searchItemsForm.studentExamId"
                @keyup.enter.native="searchStudent"
                @change="resetQueryData"
              >
              </el-input>
            </div>
            <div class="search-item">
              <span>班级</span>
              <el-select
                v-model="searchItemsForm.classNums"
                multiple
                collapse-tags
                placeholder="请选择"
                @keyup.enter.native="searchStudent"
                @change="resetQueryData"
              >
                <el-option
                  v-for="item in allClassItems"
                  :key="item"
                  :label="item + '班'"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="search-btns">
            <div class="check-0-score">
              <el-checkbox
                v-model="searchItemsForm.includingZero"
                :true-label="1"
                :false-label="0"
                @change="resetQueryData"
              >
                包含得分0的学生
              </el-checkbox>
            </div>
            <div style="display: flex; margin-right: 20px">
              <div class="appoint" @click="appointStudent">筛选指定考生</div>
              <div class="search" @click="searchStudent">
                查询<i class="el-icon-search"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="function-btns">
          <div class="result-label">查询到{{ totalPage }}名学生</div>
          <div v-if="isGeneratingStudentReportVisible" class="progress">
            正在生成第{{ progressRatio }}份报告
          </div>
          <div class="result-btns">
            <el-checkbox
              v-model="isMerge"
              :true-label="1"
              :false-label="0"
              @change="resetQueryData"
              >合并成单个文件</el-checkbox
            >
            <el-checkbox
              v-model="reportPages"
              :true-label="1"
              :false-label="0"
              @change="resetQueryData"
              >计算报告页数</el-checkbox
            >
            <el-button class="report-exercise" @click="previewParamSetting()">
              预览参数设置
            </el-button>
            <el-button
              class="report-exercise"
              :loading="buttonLoadingList[0].loading"
              :disabled="buttonLoadingList[0].disabled"
              @click="previewExerciseBook('student-preview')"
            >
              预览练习册
            </el-button>
            <el-button
              v-if="isGenerateStudentReportVisible"
              class="report-exercise"
              :loading="buttonLoadingList[1].loading"
              :disabled="buttonLoadingList[1].disabled"
              @click="createExerciseBook('student-generating')"
            >
              生成练习册
            </el-button>
            <el-button
              v-if="isGeneratingStudentReportVisible"
              class="report-exercise"
              :loading="buttonLoadingList[3].loading"
              :disabled="buttonLoadingList[3].disabled"
            >
              正在生成练习册
            </el-button>
            <el-button
              v-if="isStopStudentReportVisible"
              class="report-exercise"
              :loading="buttonLoadingList[5].loading"
              @click="stopExerciseBook('student-stop')"
            >
              中止生成任务
            </el-button>
            <el-button
              v-if="isDownloadStudentReportVisible"
              class="report-exercise"
              :loading="buttonLoadingList[4].loading"
              :disabled="buttonLoadingList[4].disabled"
              @click="downloadReport(0, 0)"
            >
              下载已有练习册
            </el-button>
            <el-button
              v-if="isClearStudentReportVisible"
              class="report-exercise"
              :loading="buttonLoadingList[2].loading"
              :disabled="buttonLoadingList[2].disabled"
              @click="clearExerciseBook('student-clear')"
            >
              清空练习册
            </el-button>
            <!-- <div class="report-exercise" @click="createExercisePaper">
              生成练习卷
            </div>
            <div class="report-exercise" @click="createExercisePaperAnswer">
              生成练习卷答案
            </div> -->
          </div>
        </div>
      </div>
      <div v-loading="scoreDataLoading" class="sreening-results">
        <div class="tips">注：表中红色姓名考生为"筛选指定考生"筛选结果</div>
        <div class="table-header">
          <div class="tabel-column text" style="width: 104px">姓名</div>
          <div class="tabel-column number" style="width: 208px">学籍号</div>
          <div class="tabel-column number" style="width: 208px">考号</div>
          <div class="tabel-column text" style="width: 390px">学校</div>
          <div
            class="tabel-column number"
            style="width: 104px; display: flex; justify-content: flex-end"
          >
            班级
            <div class="icon-order" @click="sortList('class')">
              <div
                class="asc"
                :class="{ 'order-asc': isOrderAscByClass }"
                @click.stop="iconSortList('class', 1)"
              ></div>
              <div
                class="desc"
                :class="{ 'order-desc': isOrderDescByClass }"
                @click.stop="iconSortList('class', 2)"
              ></div>
            </div>
          </div>
          <div
            class="tabel-column number"
            style="width: 104px; display: flex; justify-content: flex-end"
          >
            成绩
            <div class="icon-order" @click="sortList('score')">
              <div
                class="asc"
                :class="{ 'order-asc': isOrderAscByScore }"
                @click.stop="iconSortList('score', 1)"
              ></div>
              <div
                class="desc"
                :class="{ 'order-desc': isOrderDescByScore }"
                @click.stop="iconSortList('score', 2)"
              ></div>
            </div>
          </div>
          <div class="tabel-column text" style="width: 104px; display: flex">
            状态
            <div class="icon-order" @click="sortList('status')">
              <div
                class="asc"
                :class="{ 'order-asc': isOrderAscByStatus }"
                @click.stop="iconSortList('status', 1)"
              ></div>
              <div
                class="desc"
                :class="{ 'order-desc': isOrderDescByStatus }"
                @click.stop="iconSortList('status', 2)"
              ></div>
            </div>
          </div>
        </div>
        <div v-for="(item, key) in dataList" :key="key" class="table-content">
          <div class="info-preview">
            <div class="student-info" @click="showOrHideScoreDetail(key)">
              <div
                class="name text"
                style="width: 104px"
                :class="{ 'red-name': item.specialDisplay }"
              >
                {{ item.studentName }}
              </div>
              <div class="student-number number" style="width: 208px">
                {{ item.studentNo }}
              </div>
              <div class="test-number number" style="width: 208px">
                {{ item.studentExamId }}
              </div>
              <div class="school text" style="width: 390px">
                {{ item.schoolName }}
              </div>
              <div class="class number" style="width: 104px">
                {{ item.classNum }}
              </div>
              <div class="score number" style="width: 104px">
                {{ item.totalScore }}
              </div>
              <div class="status text" style="width: 104px">
                <span v-if="item.url && item.url.length > 0" class="generated">
                  已生成
                </span>
                <span v-else class="pending"> 待处理 </span>
              </div>
            </div>
            <div class="preview-report">
              <div
                v-if="isActiveContent == 4"
                class="btn"
                @click="previewSpecifiedStudentReport(item)"
              >
                预览报告
              </div>
            </div>
          </div>
          <div v-if="item.showScoreDetail" class="score-detail">
            <div v-if="Object.keys(item.map).length > 0">
              <div v-for="(data, k) in item.map" :key="k" class="each-score">
                <div class="exam-name">
                  {{ k.split("#")[1] }}
                </div>
                <div class="score-list">
                  <div class="score-header">
                    <div class="score-header-text score-header-num">题号</div>
                    <div class="score-header-text">分值</div>
                  </div>
                  <div
                    v-for="(val, index) in data"
                    :key="index"
                    class="score-content"
                  >
                    <div class="score-num">
                      {{ val.examPaperQuestionNumber }}
                    </div>
                    <div class="title-score">{{ val.score }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>暂无考试信息</div>
          </div>
        </div>
      </div>
      <div class="student-page">
        <el-pagination
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="pageSize"
          layout="total, sizes, prev, next, jumper"
          :total="totalPage"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
        />
      </div>
    </div>
    <!-- 原题 -->
    <div
      v-if="isActiveContent == 0"
      v-loading="questionDataLoading"
      class="original-content"
    >
      <div class="paper-content-main">
        <div class="paper-content-main-header">
          <div class="paper-content-main-button-bar">
            <el-select
              v-model="selectedExamPaperId"
              class="paper-content-main-button examPaperSelect"
              placeholder="请选择试卷"
            >
              <el-option
                v-for="item in examPaperOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <template v-for="(paper, pIndex) in examPaperOptions">
          <div
            v-if="
              paper.value > 0 &&
              (selectedExamPaperId === 0 ||
                (selectedExamPaperId > 0 &&
                  selectedExamPaperId === paper.value))
            "
            :key="pIndex"
            class="exam-paper"
          >
            <div
              v-if="isMultiExam > 0 && selectedExamPaperId === 0"
              class="paper-title"
              :title="'试卷：' + paper.label"
            >
              {{ paper.label }}
            </div>
            <template v-for="(question, index) in examQuestionOris">
              <div
                v-if="question.examPaperId === paper.value"
                :key="index"
                class="paper-question"
              >
                <div class="paper-question-header">
                  <div
                    class="paper-question-num"
                    :title="question.examPaperNum"
                  >
                    {{ question.examPaperNum }}
                  </div>
                  <div class="paper-question-type">{{ question.typeName }}</div>
                  <div class="paper-question-difficulty">
                    {{ displayDifficulty(question.difficulty) }}
                  </div>
                </div>
                <div
                  class="paper-question-content"
                  :class="{ isCollapse: question.isCollapse === 1 }"
                  @click="clickExamQuestionCollapseIcon(question, true)"
                >
                  <div v-html="question.question"></div>
                </div>
                <div class="paper-question-footer">
                  <div class="paper-question-knowledge">
                    <div
                      v-if="question.knowledgeArray.length > 0"
                      class="paper-question-knowledge-title"
                    >
                      知识点：
                    </div>
                    <template
                      v-for="(knowledge, kIndex) in question.knowledgeArray"
                    >
                      <div
                        :key="kIndex"
                        class="paper-question-knowledge-name"
                        :title="knowledge"
                      >
                        {{ knowledge }}
                      </div>
                    </template>
                  </div>
                  <div class="paper-question-action-bar">
                    <div
                      class="paper-question-collapse-icon"
                      @click="clickExamQuestionCollapseIcon(question)"
                    >
                      <template v-if="question.isCollapse === 1">
                        <i class="el-icon-caret-bottom" title="展开"></i>
                      </template>
                      <template v-else-if="question.isCollapse === 0">
                        <i class="el-icon-caret-top" title="收起"></i>
                      </template>
                    </div>
                    <div
                      class="paper-question-action"
                      @click="clickAnswerAnalysisButton(question)"
                    >
                      答案解析
                    </div>
                  </div>
                </div>
                <div
                  v-if="question.isCollapseAnswer === 0"
                  class="paper-question-below"
                >
                  <div
                    v-if="question.answer && question.answer.length > 0"
                    class="below-item"
                  >
                    【答案】
                    <div v-html="question.answer"></div>
                  </div>
                  <div
                    v-if="question.analysis && question.analysis.length > 0"
                    class="below-item"
                  >
                    【解答】
                    <div v-html="question.analysis"></div>
                  </div>
                  <div
                    v-if="question.analyse && question.analyse.length > 0"
                    class="below-item"
                  >
                    【分析】
                    <div v-html="question.analyse"></div>
                  </div>
                  <div
                    v-if="question.ability && question.ability.length > 0"
                    class="below-item"
                  >
                    【点评】
                    <div v-html="question.ability"></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <!-- 备选题 -->
    <div
      v-if="isActiveContent == 1"
      v-loading="questionDataLoading"
      class="alternative-content"
    >
      <div class="paper-content-main">
        <div class="paper-content-main-header">
          <div class="paper-content-main-button-bar">
            <el-select
              v-model="selectedExamPaperId"
              class="paper-content-main-button examPaperSelect"
              placeholder="请选择试卷"
            >
              <el-option
                v-for="item in examPaperOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="optNum"
              class="paper-content-main-button optNumSelect"
              placeholder="请选择同类题"
            >
              <el-option
                v-for="item in optNumOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <template v-for="(paper, pIndex) in examPaperOptions">
          <div
            v-if="
              paper.value > 0 &&
              (selectedExamPaperId === 0 ||
                (selectedExamPaperId > 0 &&
                  selectedExamPaperId === paper.value))
            "
            :key="pIndex"
            class="exam-paper"
          >
            <div
              v-if="isMultiExam > 0 && selectedExamPaperId === 0"
              class="paper-title"
              :title="'试卷：' + paper.label"
            >
              {{ paper.label }}
            </div>
            <template v-for="(question, index) in examQuestionOpts">
              <div
                v-if="
                  question.examPaperId === paper.value &&
                  (optNum === 0 || question.qOptNum === optNum)
                "
                :key="index"
                class="paper-question"
              >
                <div class="paper-question-header">
                  <div
                    class="paper-question-num"
                    :title="question.examPaperNum"
                  >
                    {{ question.examPaperNum }}
                  </div>
                  <div
                    class="paper-question-var-num"
                    :title="'同类题套' + question.qOptNum"
                  >
                    套{{ question.qOptNum }}
                  </div>
                  <div class="paper-question-type">{{ question.typeName }}</div>
                  <div class="paper-question-difficulty">
                    {{ displayDifficulty(question.difficulty) }}
                  </div>
                </div>
                <div
                  class="paper-question-content"
                  :class="{ isCollapse: question.isCollapse === 1 }"
                  @click="clickExamQuestionCollapseIcon(question, true)"
                >
                  <div v-html="question.question"></div>
                </div>
                <div class="paper-question-footer">
                  <div class="paper-question-knowledge">
                    <div
                      v-if="question.knowledgeArray.length > 0"
                      class="paper-question-knowledge-title"
                    >
                      知识点：
                    </div>
                    <template
                      v-for="(knowledge, kIndex) in question.knowledgeArray"
                    >
                      <div
                        :key="kIndex"
                        class="paper-question-knowledge-name"
                        :title="knowledge"
                      >
                        {{ knowledge }}
                      </div>
                    </template>
                  </div>
                  <div class="paper-question-action-bar">
                    <div
                      class="paper-question-collapse-icon"
                      @click="clickExamQuestionCollapseIcon(question)"
                    >
                      <template v-if="question.isCollapse === 1">
                        <i class="el-icon-caret-bottom" title="展开"></i>
                      </template>
                      <template v-else-if="question.isCollapse === 0">
                        <i class="el-icon-caret-top" title="收起"></i>
                      </template>
                    </div>
                    <div
                      class="paper-question-action"
                      @click="clickAnswerAnalysisButton(question)"
                    >
                      答案解析
                    </div>
                  </div>
                </div>
                <div
                  v-if="question.isCollapseAnswer === 0"
                  class="paper-question-below"
                >
                  <div
                    v-if="question.answer && question.answer.length > 0"
                    class="below-item"
                  >
                    【答案】
                    <div v-html="question.answer"></div>
                  </div>
                  <div
                    v-if="question.analysis && question.analysis.length > 0"
                    class="below-item"
                  >
                    【解答】
                    <div v-html="question.analysis"></div>
                  </div>
                  <div
                    v-if="question.analyse && question.analyse.length > 0"
                    class="below-item"
                  >
                    【分析】
                    <div v-html="question.analyse"></div>
                  </div>
                  <div
                    v-if="question.ability && question.ability.length > 0"
                    class="below-item"
                  >
                    【点评】
                    <div v-html="question.ability"></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <!-- 班级/年级报告 -->
    <classAndGradeReport
      v-if="isActiveContent == 6"
      :class-and-grade-data="classAndGradeReportData"
    ></classAndGradeReport>
    <!-- 群体报告 -->
    <groupReport
      v-if="isActiveContent == 5"
      :class-and-grade-data="classAndGradeReportData"
    ></groupReport>
    <!-- 指定考生文件导入弹窗 -->
    <el-dialog
      title="筛选指定考生"
      :visible.sync="appointStudentVisible"
      class="appoint"
    >
      <div class="upload-tips">
        通过上传 Excel 文件筛选出考生。<el-link
          type="primary"
          href="https://static.wtjy.com/analy/template/%E5%AD%A6%E7%94%9F%E7%AD%9B%E9%80%89%E6%A8%A1%E6%9D%BF.xls"
          >下载Excel模板<span class="el-icon-download"></span></el-link
        ><br />注意：<br />1、姓名为必填项。<br />2、如果筛选结果没有指定的某位考生，表示该考生未被该报告项目覆盖
        或 未上传成绩。
      </div>
      <el-upload
        :action="studentAppointURL"
        :headers="studentActionHeaders"
        :data="studentAppointData"
        accept=".xlsx,.xls"
        :show-file-list="false"
        :before-upload="handleAppointBefore"
        :on-success="handleAppointOnSuccess"
        :on-error="handleAppointOnError"
        :on-progress="handleAppointOnProgress"
        drag
        width="100%"
      >
        <div class="el-upload__text">将文件拖放到此处或点击上传</div>
      </el-upload>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="studentAppointFormSubmit()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import WTGoBack from "@/components/GoBack.vue";
import {
  reportFetchList,
  queryReportFetchList,
} from "@/core/api/exam/examstudentscore";
import { fileDownloadByUrl, getCookie } from "@/core/util/util";
import {
  getObj,
  // putObj,
  generate,
  getExamQuestion,
  getReportUrl,
  clearReportUrl,
  stopGenerate,
  getExamineeByIds,
} from "@/core/api/analy/analyreportsubject";
import { subjectOptions } from "@/core/util/constdata";
import classAndGradeReport from "@/views/analy/class-grade-report.vue";
import groupReport from "@/views/analy/group-report.vue";
import { getStore } from "@/core/util/store";

export default {
  components: {
    WTGoBack,
    classAndGradeReport,
    groupReport,
  },
  data() {
    return {
      fullscreenLoading: false, // 全局加载
      questionDataLoading: false, // 试题标签页加载
      scoreDataLoading: false, // 成绩标签页加载

      // 当前ID
      currentReportSubjectId: this.$route.query.id,
      currentReportTab: this.$route.query.tab,
      currentReportSubject: {},
      reportIdNum: 0, //报告使用的参数设置模板id
      isMultiExam: 0, // 0-单次考试 1-多次考试（阶段复习）

      //Tab按钮组
      isFirstClickTab: 0,
      functionSingalBtns: [
        { lable: "original", value: "原题" },
        { lable: "alternative", value: "备选题" },
        { lable: "score", value: "成绩" },
        { lable: "parameterSetting", value: "参数设置" },
        { lable: "studentReport", value: "学生报告" },
        { lable: "groupReport", value: "群体报告" },
        { lable: "classOrGradereport", value: "班级/年级报告" },
      ],
      functionStageBtns: [
        { lable: "original", value: "原题" },
        { lable: "alternative", value: "备选题" },
        { lable: "score", value: "成绩" },
        { lable: "parameterSetting", value: "参数设置" },
        { lable: "studentReport", value: "学生报告" },
      ],
      functionBtns: [],

      isMerge: 0, //合并成单个文件:0-不合并 1-合并
      reportPages: 1, //计算报告页数: 0-不计算 1-计算
      showScoreDetail: false, //展示小分
      showReportTips: true, //小贴士提示条

      reportName: "", //报告名称--考试名称
      currentReportType: 0, //报告类型：0-阶段报告 1-单次报告
      progressRatio: "0/0", //报告生成进度-已生成/总人数
      reportSubjectOptions: [], //报告学科组
      currentReportSubjectName: "", //当前学科
      subjectOptions: subjectOptions, //所有学科组
      isActiveContent: "4", //content展示相关，默认学生报告
      dataList: [], // 表格相关
      currentDataQuery: {}, // 请求参数
      examPaperQuestions: [],
      examQuestions: [], // 所有试题
      examQuestionOris: [], //原题
      examQuestionOpts: [], //备选题
      selectedExamPaperId: 0,
      examPaperOptions: [{ value: 0, label: "全部试卷" }],
      optNum: 0, //0-全部同类题 1-同类题套1 2-同类题套2 ……
      optNumOptions: [
        { value: 0, label: "全部同类题" },
        { value: 1, label: "同类题套1" },
        { value: 2, label: "同类题套2" },
        { value: 3, label: "同类题套3" },
        { value: 4, label: "同类题套4" },
        { value: 5, label: "同类题套5" },
      ],

      //学生报告--筛选条件   搜索功能与生成报告共用
      searchItemsForm: {
        name: "",
        studentNo: "",
        studentExamId: "",
        classNums: [],
        includingZero: 0, //包含得分0的学生: 0-不包含 1-包含
        classOrder: 0, //0-不排序 1-升序 2-降序
        scoreOrder: 0, //0-不排序 1-升序 2-降序
        statusOrder: 0, //0-不排序 1-升序 2-降序
      },
      isOrderAscByClass: false,
      isOrderDescByClass: false,
      isOrderAscByScore: false,
      isOrderDescByScore: false,
      isOrderAscByStatus: false,
      isOrderDescByStatus: false,

      //指定考生
      appointStudentVisible: false,
      studentAppointURL: "/exam/examstudentscore/uploadsearchexamstudent",
      studentActionHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": getStore({ name: "school_id" }),
      },
      studentAppointData: { reportSubjectId: this.$route.query.id },
      studentIds: "", //指定考生的id组
      excelStudentIds: [], //excel筛选的考生的ids
      excelStudentList: [], //excel筛选的考生
      isScreeningMode: false,

      isPreviewSpecifiedStudentReport: false, //悬浮“预览报告”

      //班级/年级报告
      classAndGradeReportData: "",
      allClassItems: [],

      //报告生成按钮组--禁用，加载
      buttonLoadingList: [
        {
          loading: false,
          disabled: false,
          name: "student-preview",
        },
        {
          loading: false,
          disabled: false,
          name: "student-generate",
        },
        {
          loading: false,
          disabled: false,
          name: "student-clear",
        },
        {
          loading: false,
          disabled: false,
          name: "student-generating",
        },
        {
          loading: false,
          disabled: false,
          name: "student-download",
        },
        {
          loading: false,
          disabled: false,
          name: "student-stop",
        },
      ],
      //报告生成按钮组--显示
      isGenerateStudentReportVisible: false,
      isGeneratingStudentReportVisible: false,
      isStopStudentReportVisible: false,
      isClearStudentReportVisible: false,
      isDownloadStudentReportVisible: false,

      currentReportUrl: "", //学生报告url
      isStopRequest: 0, //停止报告生成轮询
      isPreviewingPoint: false,

      isStopPollingTimeline: false, //
      stopPollingTimeline: "", //clear setTimeout

      //分页页码
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
    };
  },
  mounted() {
    window.MathJax.typesetPromise();
    //Title部分学科数据
    let reportInfo = getCookie("reportInfo");
    if (reportInfo) {
      let reportSubject = unescape(reportInfo);
      this.reportName = reportSubject.split(":")[0];
      let tempSubjectOptions = reportSubject.split(":")[1];
      tempSubjectOptions.split(",").forEach((item) => {
        let obj = {
          label: item.split("_")[0],
          value: item.split("_")[1],
          id: item.split("_")[2],
        };
        this.reportSubjectOptions.push(obj);
      });
    }
    this.currentReportType = getCookie("reportType");
    if (this.currentReportType == 1) {
      this.functionBtns = this.functionSingalBtns;
    } else {
      this.functionBtns = this.functionStageBtns;
    }
  },

  created() {
    this.$store.commit("delKeepArr");
    this.currentReportTab = this.currentReportTab || "studentReport";
    this.functionBtns.forEach((item, key) => {
      if (item.lable == this.currentReportTab) {
        this.isActiveContent = key;
      }
    });
    if (this.currentReportSubjectId) {
      // this.getReportSubjectObj();
      // this.getExamQuestion();
      this.tabHandleClick(this.currentReportTab, this.isActiveContent);
    }
    //  else {
    //   this.$message.error("页面错误，请返回后重新进入");
    // }
  },
  updated() {
    window.MathJax.startup.defaultReady();
    this.$nextTick(() => {
      this.initImg();
    });
  },
  methods: {
    initImg() {
      this.$nextTick(() => {
        var jzjxImg = document.getElementsByClassName("jzjxImg");
        let jzjxImgArr = Array.from(jzjxImg);

        var jzjxAnswerImg = document.getElementsByClassName("jzjxAnswerImg");
        let jzjxAnswerImgArr = Array.from(jzjxAnswerImg);
        jzjxImgArr = jzjxImgArr.concat(jzjxAnswerImgArr);

        let maxWidth = 1170,
          ratio = 1;
        if (jzjxImgArr.length) {
          if (jzjxImgArr[0].style.maxWidth) {
            maxWidth = this.removePxToNumber(jzjxImgArr[0].style.maxWidth);
          }
          let width = this.removePxToNumber(jzjxImgArr[0].style.width);
          if (maxWidth < width) {
            maxWidth = width;
          }
          ratio = 1170 / maxWidth;
        }
        // console.log(jzjxImgArr);
        jzjxImgArr.forEach((item) => {
          // const width = this.removePxToNumber(item.style.width);
          if (item.name == "edit") return;
          // item.style.width = width * ratio + "px";
          // item.style.display = "block";
          item.name = "edit";
          const width = this.removePxToNumber(item.style.width);
          maxWidth = this.removePxToNumber(item.style.maxWidth);
          if (maxWidth < width) {
            maxWidth = width;
          }
          ratio = 818 / maxWidth;
          item.style.width = width * ratio + "px";
          item.style.display = "block";
        });
      });
    },
    /**
     * 点击排序图标空白区域进行排序
     * field: 排序字段 class-班级 score-成绩 status-状态
     */
    sortList(field) {
      if (field === "class") {
        if (this.searchItemsForm.classOrder === 0) {
          this.searchItemsForm.classOrder = 1;
        } else if (this.searchItemsForm.classOrder === 1) {
          this.searchItemsForm.classOrder = 2;
        } else if (this.searchItemsForm.classOrder === 2) {
          this.searchItemsForm.classOrder = 0;
        }
      } else if (field === "score") {
        if (this.searchItemsForm.scoreOrder === 0) {
          this.searchItemsForm.scoreOrder = 1;
        } else if (this.searchItemsForm.scoreOrder === 1) {
          this.searchItemsForm.scoreOrder = 2;
        } else if (this.searchItemsForm.scoreOrder === 2) {
          this.searchItemsForm.scoreOrder = 0;
        }
      } else if (field === "status") {
        if (this.searchItemsForm.statusOrder === 0) {
          this.searchItemsForm.statusOrder = 1;
        } else if (this.searchItemsForm.statusOrder === 1) {
          this.searchItemsForm.statusOrder = 2;
        } else if (this.searchItemsForm.statusOrder === 2) {
          this.searchItemsForm.statusOrder = 0;
        }
      }
      this.setOrderIconStyle();
      this.getDataList();
    },
    /**
     * 点击排序图标进行排序
     * field: 排序字段 class-班级 score-成绩 status-状态
     * type: 0-不排序 1-升序 2-降序
     */
    iconSortList(field, type) {
      if (field === "class") {
        if (this.searchItemsForm.classOrder === type) {
          // 再次点击同样的图标，取消排序
          this.searchItemsForm.classOrder = 0;
        } else {
          this.searchItemsForm.classOrder = type;
        }
      } else if (field === "score") {
        if (this.searchItemsForm.scoreOrder === type) {
          // 再次点击同样的图标，取消排序
          this.searchItemsForm.scoreOrder = 0;
        } else {
          this.searchItemsForm.scoreOrder = type;
        }
      } else if (field === "status") {
        if (this.searchItemsForm.statusOrder === type) {
          // 再次点击同样的图标，取消排序
          this.searchItemsForm.statusOrder = 0;
        } else {
          this.searchItemsForm.statusOrder = type;
        }
      }
      this.setOrderIconStyle();
      this.getDataList();
    },
    setOrderIconStyle() {
      this.isOrderAscByClass = this.searchItemsForm.classOrder === 1;
      this.isOrderDescByClass = this.searchItemsForm.classOrder === 2;
      this.isOrderAscByScore = this.searchItemsForm.scoreOrder === 1;
      this.isOrderDescByScore = this.searchItemsForm.scoreOrder === 2;
      this.isOrderAscByStatus = this.searchItemsForm.statusOrder === 1;
      this.isOrderDescByStatus = this.searchItemsForm.statusOrder === 2;
    },
    tabHandleClick(label, index) {
      // console.log(index);
      //index: 0-原题 1-备选题 2-成绩 3-参数设置 4-学生报告 5-群体报告 6-班级/年级报告
      if (index != 3) {
        this.currentReportTab = label;
        this.isActiveContent = index;
        // this.subjectSwitching();
        if (index == 0 || index == 1) {
          this.getExamQuestion();
        } else if (index == 2) {
          this.getReportSubjectObj(index);
        } else if (index == 3) {
          //
        } else if (index == 4) {
          this.isFirstClickTab = 1;
          this.resetSearchFormItems();
          this.getReportSubjectObj();
        }
      } else {
        this.$router.push({
          name: "manageList",
          query: {
            id: this.currentReportSubjectId,
          },
        });
      }
    },
    //关闭tips
    closeReportTips() {
      this.showReportTips = false;
    },

    //待开发提示
    toBeDeveloped() {
      this.$message({
        showClose: true,
        message: "内容待开发，请耐心等待",
      });
    },
    //展示小分
    showOrHideScoreDetail(key) {
      this.dataList.forEach((item, index) => {
        if (index == key) {
          if (item.showScoreDetail) {
            item.showScoreDetail = false;
          } else {
            item.showScoreDetail = true;
          }
        }
      });
      this.dataList = JSON.parse(JSON.stringify(this.dataList));
    },
    //查询学生
    searchStudent() {
      this.isScreeningMode = false;
      this.pageIndex = 1;
      this.studentIds = "";
      this.getDataList();
    },
    //指定考生
    appointStudent() {
      this.appointStudentVisible = true;
    },
    handleAppointBefore(file) {
      if (!(file.name.endsWith(".xlsx") || file.name.endsWith(".xls"))) {
        this.$message.error("只能上传 Excel 文件！");
        return false;
      }
      let is10M = file.size / 1024 / 1024 <= 10;
      if (!is10M) {
        this.$message.error("文件大小不能超过 10 MB！");
        return false;
      }
      return true;
    },
    handleAppointOnProgress() {
      this.appointStudentVisible = false;
      this.fullscreenLoading = true;
    },
    handleAppointOnSuccess(response, file, fileList) {
      this.fullscreenLoading = false;
      this.handleAppointFileRemove(fileList);
      if (response.code === 0) {
        this.isScreeningMode = true;
        this.pageIndex = 1;
        this.totalPage = response.data.total;
        this.pageSize = this.totalPage;
        this.dataList = response.data.records;
        let _studentIds = [];
        this.dataList.forEach((item) => {
          item.showScoreDetail = false;
          item.specialDisplay = true;
          _studentIds.push(item.studentId);
        });
        //this.excelStudentIds--获取对应考生用   this.studentIds--报告用
        this.excelStudentIds = _studentIds;
        this.searchType = true;
        if (_studentIds.length > 1) {
          this.studentIds = _studentIds.join(",");
        } else {
          this.studentIds = _studentIds.toString();
        }
        // console.log(this.studentIds);
        this.resetQueryData();
      } else {
        this.$message({
          showClose: true,
          type: "warning",
          message: response.msg,
          dangerouslyUseHTMLString: true,
          duration: 30000,
        });
      }
    },
    handleAppointOnError(err, file, fileList) {
      this.fullscreenLoading = false;
      this.handleAppointFileRemove(fileList);
    },
    handleAppointFileRemove(fileList) {
      fileList.splice(0, 1);
    },
    studentAppointFormSubmit() {
      this.pageIndex = 1;
      this.getDataList();
    },
    //学科切换
    subjectSwitching() {
      if (this.currentReportSubjectId != this.currentReportSubjectName) {
        this.currentReportSubjectId = this.currentReportSubjectName;
        //isActiveContent:0-原题 1-备选题 2-成绩 3-参数设置 4-学生报告 5-群体报告 6-班级/年级报告
        if (this.isActiveContent == 0 || this.isActiveContent == 1) {
          this.getExamQuestion();
        } else if (this.isActiveContent == 2) {
          this.getReportSubjectObj();
        } else if (this.isActiveContent == 3) {
          //
        } else if (this.isActiveContent == 4) {
          this.resetSearchFormItems();
          this.getReportSubjectObj();
        } else if (this.isActiveContent == 5) {
          //
        } else if (this.isActiveContent == 6) {
          //
        }
      }
    },
    //
    findLabelByValue(arr, val) {
      for (let index in arr) {
        if (arr[index].value == val) {
          return arr[index].label;
        }
      }
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },

    getReportSubjectObj(val) {
      // 初始化报告按钮状态
      // 2-成绩 不进入此方法
      // console.log(val);
      if (val != 2) {
        this.refreshReportButtonVisible();
      }

      //获取基础数据
      getObj(this.currentReportSubjectId).then((response) => {
        if (response.data.code === 0 && response.data.data != null) {
          this.currentReportSubject = response.data.data;
          if (this.currentReportSubject.examIds.indexOf(",") > 0) {
            this.isMultiExam = 1;
          }
          this.classAndGradeReportData = response.data.data; //班级/年级子组件数据
          if (
            this.currentReportSubject.classNums &&
            this.currentReportSubject.classNums.length > 0
          ) {
            this.allClassItems = this.currentReportSubject.classNums.split(",");
          }
          // 当前数据参数
          this.currentDataQuery = {
            reportSubjectId: this.currentReportSubjectId,
          };
          this.currentReportSubjectName = this.findLabelByValue(
            this.subjectOptions,
            this.currentReportSubject.subjectId
          );
          this.reportIdNum = this.currentReportSubject.setting0;
          // 获取数据列表
          this.getDataList();
        } else {
          // this.$message.error("获取报告学科数据失败，请稍后再试");
        }
      });
      // .catch(err => {
      //   this.$message.error(err);
      // });
    },
    refreshReportButtonVisible() {
      // 学生报告相关
      this.getReportGenerationStatus();
    },
    refreshExcelDataList() {
      // let obj = {
      //   reportSubjectId: this.currentReportSubjectId,
      //   studentIds: this.excelStudentIds
      // };
      let data = new URLSearchParams();
      data.append("reportSubjectId", this.currentReportSubjectId);
      data.append("studentIds", this.excelStudentIds);
      getExamineeByIds(data).then((res) => {
        this.excelStudentList = res.data.data;
        this.dataList = res.data.data;
        this.dataList.forEach((item) => {
          item.specialDisplay = true;
        });
      });
    },
    // 获取数据列表
    getDataList() {
      if (this.isScreeningMode) {
        this.refreshExcelDataList();
        return;
      }
      this.scoreDataLoading = true;
      if (this.searchItemsForm) {
        if (
          (this.searchItemsForm.name && this.searchItemsForm.name.length > 0) ||
          (this.searchItemsForm.studentNo &&
            this.searchItemsForm.studentNo.length > 0) ||
          (this.searchItemsForm.studentExamId &&
            this.searchItemsForm.studentExamId.length > 0) ||
          (this.searchItemsForm.classNums &&
            this.searchItemsForm.classNums.length > 0) ||
          this.searchItemsForm.includingZero === 1 ||
          this.searchItemsForm.classOrder > 0 ||
          this.searchItemsForm.scoreOrder > 0 ||
          this.searchItemsForm.statusOrder > 0
        ) {
          this.queryFetchList();
        } else {
          this.normalFetchList();
        }
      } else {
        this.normalFetchList();
      }
    },
    normalFetchList() {
      reportFetchList(
        Object.assign(
          {
            current: this.pageIndex,
            size: this.pageSize,
          },
          this.currentDataQuery
        )
      )
        .then((response) => {
          this.totalPage = response.data.data.total;
          this.dataList = response.data.data.records;
          this.dataList.forEach((item) => {
            item.showScoreDetail = false;
          });
          this.scoreDataLoading = false;
        })
        .catch(() => {
          this.scoreDataLoading = false;
        });
    },
    queryFetchList() {
      queryReportFetchList(
        Object.assign(
          {
            current: this.pageIndex,
            size: this.pageSize,
          },
          this.currentDataQuery,
          this.searchItemsForm
        )
      )
        .then((response) => {
          this.totalPage = response.data.data.total;
          this.dataList = response.data.data.records;
          this.dataList.forEach((item) => {
            item.showScoreDetail = false;
          });
          this.scoreDataLoading = false;
        })
        .catch(() => {
          this.scoreDataLoading = false;
        });
    },
    // 获取原题和备选题
    getExamQuestion() {
      this.questionDataLoading = true;
      getExamQuestion(this.currentReportSubjectId)
        .then((response) => {
          this.examPaperQuestions = response.data.data.examPaperQuestions;
          this.examQuestions = response.data.data.examQuestions;
          this.generateExamPaperOptions();
          this.handleExamQuestion();
          this.questionDataLoading = false;
        })
        .catch(() => {
          this.questionDataLoading = false;
        });
    },
    generateExamPaperOptions() {
      this.examPaperOptions = [{ value: 0, label: "全部试卷" }];
      for (let i = 0; i < this.examPaperQuestions.length; i++) {
        let examPaperQuestion = this.examPaperQuestions[i];
        let id = examPaperQuestion.examPaperId;
        let name = examPaperQuestion.examPaperName;
        let contain = false;
        for (let oi = 0; oi < this.examPaperOptions.length; oi++) {
          let examPaperOption = this.examPaperOptions[oi];
          if (examPaperOption.value === id) {
            contain = true;
            break;
          }
        }
        if (!contain) {
          this.examPaperOptions.push({ value: id, label: name });
        }
      }
    },
    handleExamQuestion() {
      this.examQuestionOris = [];
      this.examQuestionOpts = [];
      let checkedExamQuestionMaterialOrders = []; // 二维数组，已处理的材料题试题的试卷ID和题号
      cycle: for (let i = 0; i < this.examPaperQuestions.length; i++) {
        // 遍历切题数据，获取原题
        let examPaperQuestion = this.examPaperQuestions[i];
        let examPaperId = examPaperQuestion.examPaperId;
        if (
          examPaperQuestion.examQuestionMaterialOrder &&
          examPaperQuestion.examQuestionMaterialOrder.length > 0
        ) {
          // 材料题
          // 只处理第1小题的切题数据
          let found = false;
          for (
            let ci = 0;
            ci < checkedExamQuestionMaterialOrders.length;
            ci++
          ) {
            if (
              checkedExamQuestionMaterialOrders[ci][0] === examPaperId &&
              checkedExamQuestionMaterialOrders[ci][1] ===
                examPaperQuestion.examQuestionMaterialOrder
            ) {
              found = true;
              continue cycle;
            }
          }
          if (!found) {
            checkedExamQuestionMaterialOrders.push([
              examPaperId,
              examPaperQuestion.examQuestionMaterialOrder,
            ]);
          }
        }
        let examQuestion = this.getExamQuestionById(
          examPaperQuestion.examQuestionId
        );
        if (examQuestion) {
          examQuestion.examPaperId = examPaperId;
          examQuestion.examPaperNum = examPaperQuestion.examQuestionOrder; // 试题题号
          // 有材料题题号则使用材料题题号作为试题题号
          if (
            examPaperQuestion.examQuestionMaterialOrder &&
            examPaperQuestion.examQuestionMaterialOrder.length > 0
          ) {
            examQuestion.examPaperNum =
              examPaperQuestion.examQuestionMaterialOrder;
          }
          examQuestion.knowledgeArray = this.getKnowledgeArrayByNames(
            examQuestion.knowledgeNames
          ); // 设置知识点数组
          examQuestion.display = 1; // 是否显示 原题默认显示
          examQuestion.isCollapse = 0; // 是否收起状态
          examQuestion.isCollapseAnswer = 1; // 是否收起答案
          examQuestion.examPaperQuestionIds = examPaperQuestion.id; // 试题对应的切题表数据ID，用于编辑试题功能
          examQuestion.displayOpt = 0; // 是否显示变式题
          // 获取该试题的变式题
          examQuestion.examQuestionOpts = []; // 变式题数组
          for (let oi = 1; oi <= 5; oi++) {
            if (examPaperQuestion["examQuestionOpt" + oi] > 0) {
              let examQuestionOpt = this.getExamQuestionById(
                examPaperQuestion["examQuestionOpt" + oi]
              );
              if (examQuestionOpt) {
                examQuestionOpt.examPaperId = examPaperId;
                examQuestionOpt.examPaperNum = examQuestion.examPaperNum;
                examQuestionOpt.knowledgeArray = this.getKnowledgeArrayByNames(
                  examQuestionOpt.knowledgeNames
                ); // 设置知识点数组
                examQuestionOpt.display = 0; // 是否显示 材料题默认隐藏
                examQuestionOpt.isCollapse = 0; // 是否收起状态
                examQuestionOpt.isCollapseAnswer = 1; // 是否收起答案
                examQuestionOpt.examPaperQuestionIds = examPaperQuestion.id; // 试题对应的切题表数据ID，用于编辑试题功能
                examQuestionOpt.qOptNum = oi; // 变式题套号
                this.examQuestionOpts.push(examQuestionOpt);
              }
            }
          }
          this.examQuestionOris.push(examQuestion);
          // this.$nextTick(() => {
          //   var jzjxImg = document.getElementsByClassName("jzjxImg");
          //   let jzjxImgArr = Array.from(jzjxImg);
          //   let maxWidth = 1170,
          //     ratio = 1;
          //   if (jzjxImgArr.length) {
          //     if (jzjxImgArr[0].style.maxWidth) {
          //       maxWidth = this.removePxToNumber(jzjxImgArr[0].style.maxWidth);
          //     }
          //     let width = this.removePxToNumber(jzjxImgArr[0].style.width);
          //     if (maxWidth < width) {
          //       maxWidth = width;
          //     }
          //     ratio = 1170 / maxWidth;
          //   }
          //   jzjxImgArr.forEach((item) => {
          //     const width = this.removePxToNumber(item.style.width);
          //     item.style.width = width * ratio + "px";
          //     item.style.display = "block";
          //   });
          // });
        }
      }
    },
    removePxToNumber(val) {
      let data = val.replace("px", "");
      return Number(data);
    },
    getExamQuestionById(id) {
      for (let i = 0; i < this.examQuestions.length; i++) {
        let examQuestion = this.examQuestions[i];
        if (examQuestion.id === id) {
          return JSON.parse(JSON.stringify(examQuestion));
        }
      }
    },
    getKnowledgeArrayByNames(knowledgeNames) {
      let array = [];
      if (knowledgeNames && knowledgeNames.length > 0) {
        array = knowledgeNames.split("#");
      }
      return array;
    },
    displayDifficulty(difficulty) {
      let str = "容易";
      if (difficulty <= 1 && difficulty >= 0.9) {
        str = "容易";
      } else if (difficulty < 0.9 && difficulty >= 0.7) {
        str = "较易";
      } else if (difficulty < 0.7 && difficulty >= 0.5) {
        str = "一般";
      } else if (difficulty < 0.5 && difficulty >= 0.3) {
        str = "较难";
      } else if (difficulty < 0.3 && difficulty >= 0) {
        str = "困难";
      }
      return str;
    },
    clickExamQuestionCollapseIcon(question, isClickContent) {
      if (isClickContent && question.isCollapse === 0) {
        return;
      }
      question.isCollapse = 1 - question.isCollapse;
    },
    clickAnswerAnalysisButton(question) {
      question.isCollapseAnswer = 1 - question.isCollapseAnswer;
    },
    //再次更新按钮组状态
    refreshReportBtnsLoadingAndDisabled(name) {
      this.buttonLoadingList.map((item) => {
        item.disabled = true; //所有按钮禁用
        item.loading = false;
        if (item.name == name) {
          item.disabled = false;
          item.loading = true;
        }
        if (!name) {
          item.disabled = false;
          item.loading = false;
        }
      });
    },
    /**
     * 参数预览/analy/editReport
     * id: 报告id
     * reportIdNum: 参数模板id
     * type: 报告类型
     * isDefault=0
     */
    previewParamSetting() {
      if (this.reportIdNum && this.reportIdNum > 0) {
        this.$router.push({
          name: "ReportParameters",
          query: {
            id: this.$route.query.id,
            reportIdNum: this.reportIdNum,
            type: 0,
            isDefault: 0,
          },
        });
      }
    },
    /**
     * 学生报告
     * name: 按钮标识 -preview:预览 -
     * type: 0-学生报告 1-班级报告 2-年级报告
     * isPreview: 0-生成 1-预览
     * isAll: 0-部分下载(当前已生成) 1-全部下载
     */
    previewExerciseBook(name) {
      if (!this.searchType) {
        this.studentIds = "";
      }
      this.isStopPollingTimeline = false;
      this.isStopRequest = 0;
      this.generateReport(name, 0, 1, 0);
    },
    createExerciseBook(name) {
      this.isStopPollingTimeline = false;
      this.currentDataQuery.studentIds = "";
      if (!this.searchType) {
        this.studentIds = "";
      }

      this.generateReport(name, 0, 0, 0);
    },
    clearExerciseBook(name) {
      this.$confirm("确认清空已生成报告吗？", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      }).then(() => {
        this.confirmEmptyExerciseBookTips(name);
      });
    },
    confirmEmptyExerciseBookTips(name) {
      this.refreshReportBtnsLoadingAndDisabled(name);
      clearReportUrl(this.currentDataQuery, 0)
        .then((res) => {
          if (res.data.code == 0) {
            this.setButtonStatus(0, "", 0);
            this.refreshReportBtnsLoadingAndDisabled();
            this.getDataList();
          }
        })
        .catch(() => {
          this.refreshReportBtnsLoadingAndDisabled();
        });
    },
    stopExerciseBook(name) {
      this.refreshReportBtnsLoadingAndDisabled(name);
      this.isStopPollingTimeline = true;
      stopGenerate(
        Object.assign(
          {
            reportType: 0,
          },
          this.currentDataQuery
        )
      )
        .then((res) => {
          if (res.data.code == 0) {
            if (this.stopPollingTimeline) {
              clearTimeout(this.stopPollingTimeline);
            }
            this.setButtonStatus(0, "", 0);
            this.refreshReportBtnsLoadingAndDisabled();
            this.getDataList();
          }
        })
        .catch(() => {
          this.refreshReportBtnsLoadingAndDisabled();
        });
    },
    //预览指定学生
    previewSpecifiedStudentReport(info) {
      if (this.isPreviewingPoint) {
        // this.$message.error("已有报告正在生成，请确认后再试");
      } else {
        this.isPreviewSpecifiedStudentReport = true;
        this.studentIds = info.studentId;
        this.isStopRequest = 0;
        this.isPreviewingPoint = true;
        this.generateReport("student-preview", 0, 1, 0);
      }
    },
    //生成练习卷
    createExercisePaper() {
      this.toBeDeveloped();
    },
    //生成练习卷答案
    createExercisePaperAnswer() {
      this.toBeDeveloped();
    },
    setButtonStatus(type, name, index) {
      //index: 0-生成 1-下载/清空 2-正在生成
      this.refreshReportBtnsLoadingAndDisabled(name);
      if (type == 0) {
        if (index == 0) {
          this.isDownloadStudentReportVisible = false;
          this.isClearStudentReportVisible = false;
          this.isGenerateStudentReportVisible = true;
          this.isGeneratingStudentReportVisible = false;
          this.isStopStudentReportVisible = false;
        }
        if (index == 1) {
          this.isDownloadStudentReportVisible = true;
          this.isClearStudentReportVisible = true;
          this.isGenerateStudentReportVisible = true;
          this.isGeneratingStudentReportVisible = false;
          this.isStopStudentReportVisible = false;
        }
        if (index == 2) {
          this.isDownloadStudentReportVisible = false;
          this.isClearStudentReportVisible = false;
          this.isGenerateStudentReportVisible = false;
          this.isGeneratingStudentReportVisible = true;
          this.isStopStudentReportVisible = true;
        }
      }
    },
    resetSearchFormItems() {
      this.searchItemsForm = {
        name: "",
        studentNo: "",
        studentExamId: "",
        classNums: [],
        includingZero: 0, //包含得分0的学生: 0-不包含 1-包含
        classOrder: 0, //0-不排序 1-升序 2-降序
        scoreOrder: 0, //0-不排序 1-升序 2-降序
        statusOrder: 0, //0-不排序 1-升序 2-降序
      };
    },
    resetQueryData() {
      // 当前数据参数
      let searchItems = JSON.parse(JSON.stringify(this.searchItemsForm));
      searchItems.classNums = searchItems.classNums.join(",");
      this.currentDataQuery = Object.assign(
        {
          reportSubjectId: this.currentReportSubjectId,
        },
        searchItems
      );
      this.currentDataQuery.countPages = this.reportPages;
      this.currentDataQuery.studentIds = this.studentIds;
      this.currentDataQuery.isMerge = this.isMerge;
    },
    // 生成学生/班级/年级报告报告
    generateReport(name, type, isPreview) {
      if (this.isStopPollingTimeline) {
        return;
      }
      if (isPreview != 1) {
        this.setButtonStatus(type, name, 2);
      } else {
        this.refreshReportBtnsLoadingAndDisabled("student-preview");
      }
      this.resetQueryData();
      let data = {
        isPreview: isPreview || 0,
        reportType: type || 0,
      };
      // if (this.studentIds.length > 0) {
      data.studentIds = this.studentIds;
      // }
      // console.log(data);
      // console.log(this.studentIds);
      // this.studentIds
      generate(Object.assign(this.currentDataQuery, data))
        .then((res) => {
          if (this.isStopPollingTimeline) {
            return;
          }
          if (res.data.code == 0) {
            if (res.data.data) {
              if (res.data.data.indexOf("http") == 0) {
                this.setButtonStatus(type, "", 1);
                this.getDataList();
                this.isPreviewingPoint = false;
                let arr = res.data.data.split("，");
                arr.map((index) => {
                  fileDownloadByUrl(index);
                });
              } else if (res.data.data.indexOf("/") > -1) {
                this.progressRatio = res.data.data;
                this.setButtonStatus(0, "student-generating", 2);
              } else {
                if (isPreview == 1) {
                  this.refreshReportBtnsLoadingAndDisabled("student-preview");
                } else {
                  this.setButtonStatus(type, name, 2);
                }
                if (!this.isStopPollingTimeline) {
                  this.startGetReportUrl(type, isPreview);
                }
              }
            }
          }
        })
        .catch(() => {
          // this.$message.error(err);
          this.setButtonStatus(type, "", 0);
          this.isPreviewingPoint = false;
          this.refreshReportBtnsLoadingAndDisabled();
        });
    },
    startGetReportUrl(type, isPreview) {
      if (this.isStopPollingTimeline) {
        return;
      }
      let thisObj = this;
      this.stopPollingTimeline = setTimeout(function () {
        thisObj.sendGetReportUrl(type, isPreview);
      }, 3000);
    },
    sendGetReportUrl(type, isPreview) {
      this.isStopRequest++;
      if (isPreview == 1 && this.isStopRequest > 50) {
        this.refreshReportBtnsLoadingAndDisabled();
        this.isPreviewingPoint = false;
        this.timeoutToStopGenerate();
        return;
      }
      this.resetQueryData();
      getReportUrl(
        Object.assign(
          {
            reportType: type,
            isPreview: isPreview,
          },
          this.currentDataQuery
        )
      )
        .then((data) => {
          if (this.isStopPollingTimeline) {
            this.isPreviewingPoint = false;
            return;
          }
          let currentUrl = data.data.data;
          if (currentUrl == "") {
            //空字符串，轮询
            if (!isPreview) {
              if (type == 0) {
                this.setButtonStatus(type, "student-generating", 2);
              }
            } else {
              if (type == 0) {
                this.refreshReportBtnsLoadingAndDisabled("student-preview");
              }
            }
            this.startGetReportUrl(type, isPreview);
          } else if (currentUrl.length > 0) {
            if (currentUrl == 1) {
              // if (!isPreview) {
              //   if (type == 0) {
              //     this.setButtonStatus(type, "student-generating", 2);
              //   }
              // } else {
              //   if (type == 0) {
              //     this.refreshReportBtnsLoadingAndDisabled("student-preview");
              //   }
              // }
              // this.startGetReportUrl(type, isPreview);
            } else if (currentUrl.indexOf("http") == 0) {
              //http开头,报告生成成功
              this.setButtonStatus(type, "", 1);
              this.getDataList();
              this.isPreviewingPoint = false;
              fileDownloadByUrl(currentUrl);
            } else if (currentUrl.indexOf("/") > -1) {
              //进度比展示，表示生成途中，轮询
              this.progressRatio = currentUrl;
              // this.setButtonStatus(0, "student-generating", 2);
              if (!isPreview) {
                if (type == 0) {
                  this.setButtonStatus(type, "student-generating", 2);
                }
              } else {
                if (type == 0) {
                  this.refreshReportBtnsLoadingAndDisabled("student-preview");
                }
              }
              this.startGetReportUrl(type, isPreview);
            } else {
              this.isPreviewingPoint = false;
              this.setButtonStatus(type, "", 0);
            }
          } else {
            this.isPreviewingPoint = false;
            this.setButtonStatus(type, "", 0);
          }
        })
        .catch(() => {
          this.isPreviewingPoint = false;
          this.setButtonStatus(type, "", 0);
        });
    },
    timeoutToStopGenerate() {
      stopGenerate(
        Object.assign(
          {
            reportType: 0,
          },
          this.currentDataQuery
        )
      )
        .then((res) => {
          if (res.data.code == 0) {
            if (this.stopPollingTimeline) {
              clearTimeout(this.stopPollingTimeline);
            }
            // this.setButtonStatus(0, "", 0);
            // this.refreshReportBtnsLoadingAndDisabled();
            // this.getDataList();
          }
        })
        .catch(() => {
          // this.$message.error(res.data.msg);
          // this.refreshReportBtnsLoadingAndDisabled();
        });
    },
    getReportGenerationStatus() {
      this.resetQueryData();
      let tempObj = {
        reportType: 0,
        isPreview: 1,
      };
      if (this.isFirstClickTab == 1) {
        tempObj.isFirstClickTab = 1;
      }
      // console.log("执行了这个方法");
      getReportUrl(Object.assign(tempObj, this.currentDataQuery))
        .then((data) => {
          this.isFirstClickTab = 0;
          this.currentReportUrl = data.data.data;
          // this.currentReportUrl = "11/222";
          if (this.currentReportUrl) {
            if (this.currentReportUrl.indexOf("http") == 0) {
              //http开头,已经有生成成功报告
              this.setButtonStatus(0, "", 1);
            } else if (this.currentReportUrl.indexOf("/") > -1) {
              this.progressRatio = this.currentReportUrl;
              this.setButtonStatus(0, "student-generating", 2);
            } else {
              this.setButtonStatus(0, "", 0);
            }
          } else {
            this.setButtonStatus(0, "", 0);
          }
        })
        .catch(() => {
          this.setButtonStatus(0, "", 0);
        });
    },
    /**
     * 下载报告
     * type
     * isAll: 0-部分下载（当前已生成）1-全部下载
     */
    downloadReport(type, isAll) {
      // if (type == 0) {
      this.refreshReportBtnsLoadingAndDisabled("student-download");
      this.resetQueryData();
      let getURLQuery = {
        reportType: type,
        isPreview: 0,
        isAll: isAll,
      };
      getReportUrl(Object.assign(getURLQuery, this.currentDataQuery))
        .then((data) => {
          this.currentReportUrl = data.data.data;
          if (this.currentReportUrl) {
            if (this.currentReportUrl.indexOf("http") == 0) {
              //http开头,已经有生成成功报告
              this.setButtonStatus(0, "", 1);
              this.refreshReportBtnsLoadingAndDisabled();
              fileDownloadByUrl(this.currentReportUrl);
            } else {
              this.setButtonStatus(0, "", 1);
              this.refreshReportBtnsLoadingAndDisabled();
            }
          } else {
            this.setButtonStatus(0, "", 1);
          }
        })
        .catch(() => {
          this.setButtonStatus(0, "", 1);
          this.refreshReportBtnsLoadingAndDisabled();
        });
      // }
    },
  },
};
</script>
<style lang="scss">
/* 自带样式修改 */
.student-report {
  .appoint {
    .el-dialog__header {
      padding: 20px 20px 0px;
    }
    .el-dialog__body {
      padding: 0 20px;
      .upload-tips {
        margin: 20px 0;
      }
      .el-upload {
        width: 100%;
        .el-upload-dragger {
          padding: 50px 0;
          width: unset;
          height: unset;
        }
      }
    }
  }
  .report-score-content .function-btns .result-btns {
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      cursor: not-allowed;
    }
    .el-icon-loading {
      color: #ffffff;
    }
  }
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus,
  .el-select .el-input__inner:focus,
  .el-input__inner:hover,
  .el-select .el-input.is-focus .el-input__inner,
  .el-input__inner {
    border: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
  .report-subject {
    .el-select {
      .el-input__inner {
        border: 1px solid rgba(0, 0, 0, 0.05) !important;
        background-color: transparent !important;
      }
    }
  }
  .report-score-content {
    .el-tag.el-tag--info {
      margin-right: 6px !important;
    }
  }
}
.paper-question-content .jzjxImg {
  max-width: 100%;
  width: unset !important;
  height: unset !important;
}
</style>
<style scoped lang="scss">
.student-report {
  width: 1220px;
  margin: 0 auto;
  padding-bottom: 32px;
  .report-title {
    display: flex;
    justify-content: space-between;
    .page-title {
      padding: 16px 0;
      font-size: 14px;
      text-align: left;
      color: rgba(0, 0, 0, 0.85);
    }
    .report-subject {
      padding: 6px 0;
      font-size: 14px;
      text-align: justify;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;
      .el-select {
        width: 100px;
        .el-input__inner {
          border: none !important;
          background-color: #ffffff !important;
        }
      }
    }
  }
  .report-header {
    .report-tips {
      width: 1216px;
      height: 80px;
      margin: 0 auto;
      padding: 4px 4px 18px 20px;
      border-radius: 4px;
      background-color: #ad6edd;
      .close-btn {
        width: 16px;
        height: 16px;
        margin-left: 1176px;
        img {
          width: 16px;
          height: 16px;
        }
      }
      .tips {
        display: flex;
        .tips-icon {
          margin-right: 20px;
          img {
            width: 40px;
            height: 40px;
          }
        }
        .tips-text {
          & :first-child {
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
          }
          & :last-child {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.75);
          }
        }
      }
    }
    .report-tab {
      display: flex;
      width: 1216px;
      margin: 32px auto;
      .page-name {
        font-size: 24px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
        width: 100px;
      }
      .page-btns {
        display: flex;
        cursor: pointer;
        justify-content: center;
        width: 1110px;
        & :not(:last-child) {
          margin-right: 8px;
        }
        div {
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 10px 16px;
          border-radius: 20px;
        }
        .current-btn {
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 10px 16px;
          border-radius: 20px;
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
  .report-score-content {
    .report-screening {
      margin: 33px auto;
      padding: 32px 80px;
      background-color: rgba(0, 0, 0, 0.05);
      .search-label {
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 32px;
      }
      .search-items {
        display: flex;
        margin-bottom: 32px;
        .search-item {
          width: 240px;
          span {
            font-family: NotoSansSC;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.5);
            display: block;
            margin-bottom: 8px;
          }
          // .el-select {
          //   width: 352px;
          // }
        }
        & :not(:last-child) {
          margin-right: 33px;
        }
      }
      .search-btns {
        height: 40px;
        display: flex;
        justify-content: space-between;
        .check-0-score {
          padding: 10px 0;
        }
        .appoint {
          line-height: 40px;
          margin-right: 20px;
          cursor: pointer;
          & :hover {
            color: #2474ed;
          }
        }
        .search {
          font-size: 14px;
          color: #ffffff;
          padding: 10px 16px;
          border-radius: 4px;
          background-color: #2474ed;
          cursor: pointer;
          i {
            margin-left: 33px;
            color: #ffffff;
          }
        }
      }
    }
    .function-btns {
      width: 1216px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      align-items: center;
      .result-label {
        font-size: 20px;
        font-weight: bold;
        text-align: left;
        color: rgba(0, 0, 0, 0.85);
        padding: 6px 0;
      }
      .progress {
        color: #2474ed;
        font-size: 14px;
        font-weight: 600;
      }
      .result-btns {
        display: flex;
        .el-checkbox {
          padding: 10px 0;
          margin-right: 32px;
          color: rgba(0, 0, 0, 0.85);
        }
        .report-exercise {
          padding: 0px 16px;
          border-radius: 4px;
          background-color: #2474ed;
          color: #ffffff;
          cursor: pointer;
          height: 40px;
          line-height: 40px;
        }
        .report-exercise + .report-exercise {
          margin-left: 8px;
        }
      }
    }
    .sreening-results {
      width: 1216px;
      margin: 10px auto 0;
      .tips {
        margin: 0 14px 10px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
      }
      .table-header {
        display: flex;
        .tabel-column {
          padding: 10px 16px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 1px 0 0 rgb(0 0 0 / 10%),
            0 1px 0 0 rgb(0 0 0 / 10%);
        }
        .text {
          text-align: left;
        }
        .number {
          text-align: right;
        }
        .icon-order {
          width: 22px;
          height: 20px;
          cursor: pointer;
          overflow: hidden;
          .asc,
          .desc {
            width: 0px;
            height: 0px;
            border-width: 6px;
            border-style: solid;
            margin-left: 10px;
            &:first-child {
              margin-bottom: 4px;
              margin-top: -3px;
            }
          }
          .asc {
            border-color: transparent transparent rgba(0, 0, 0, 0.5) transparent;
          }
          .desc {
            border-color: rgba(0, 0, 0, 0.5) transparent transparent transparent;
          }
          .order-asc {
            border-color: transparent transparent #2474ed transparent;
          }
          .order-desc {
            border-color: #2474ed transparent transparent transparent;
          }
        }
      }
      .table-content {
        .info-preview {
          display: flex;
          &:hover {
            .preview-report {
              display: block;
              cursor: pointer;
            }
          }
          .student-info {
            display: flex;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            div {
              padding: 10px 16px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.85);
            }
            .text {
              text-align: left;
            }
            .number {
              text-align: right;
            }
            .red-name {
              color: #ff0000;
            }
            .generated {
              padding: 4px 8px;
              border-radius: 4px;
              border: solid 1px #2474ed;
              background-color: #e8faf5;
              color: #2474ed;
            }
            .pending {
              padding: 4px 8px;
              border-radius: 4px;
              border: solid 1px #eeac1e;
              background-color: #fef7e9;
              color: #eeac1e;
            }
          }
          .preview-report {
            display: none;
            .btn {
              border-radius: 4px;
              min-width: 86px;
              background-color: #2474ed;
              color: #ffffff;
              margin-left: 10px;
              padding: 8px;
              text-align: center;
            }
          }
        }
        .score-detail {
          background-color: rgba(0, 0, 0, 0.05);
          padding: 32px;

          .each-score {
            .exam-name {
              margin-bottom: 10px;
              font-size: 14px;
            }
            .score-list {
              max-width: 1152px;
              overflow-x: auto;
              display: flex;
              .score-header {
                flex-shrink: 0;
                .score-header-text {
                  height: 40px;
                  background-color: #ffffff;
                  line-height: 40px;
                  font-size: 14px;
                  padding: 0 32px;
                }
                .score-header-num {
                  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                }
              }
              .score-content {
                flex-shrink: 0;
                background-color: #ffffff;
                line-height: 40px;
                font-size: 14px;
                text-align: center;
                .score-num {
                  color: rgba(0, 0, 0, 0.5);
                  padding: 0 8px;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                  height: 40px;
                }
                .title-score {
                  color: rgba(0, 0, 0, 0.85);
                  background-color: #ffffff;
                  padding: 0 8px;
                  min-width: 40px;
                }
              }
            }
          }
          .each-score + .each-score {
            margin-top: 18px;
          }
        }
      }
    }
    .student-page {
      width: 1152px;
      height: 56px;
      margin: 24px auto 10px;
      .el-pagination {
        text-align: right;
      }
    }
  }
  .group-report-cotent {
    border-top: 2px solid rgba(0, 0, 0, 0.05);
    min-height: 100px;
    padding: 20px;
    .btn {
      padding: 10px 16px;
      cursor: pointer;
      background-color: #2474ed;
      color: #ffffff;
    }
  }
  .original-content .paper-content-main,
  .alternative-content .paper-content-main {
    .paper-content-main-header {
      display: flex;
      width: 100%;
      .paper-content-main-button-bar {
        display: flex;
        align-items: center;
        text-align: center;
        height: 32px;
        margin: 26px 8px 10px auto;
        .paper-content-main-button {
          padding: 6px 16px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          cursor: pointer;
          i {
            margin-right: 8px;
          }
        }
        .paper-content-main-button + .paper-content-main-button {
          margin-left: 14px;
        }
        .examPaperSelect {
          width: 200px;
        }
        .optNumSelect {
          width: 150px;
        }
      }
    }
    .exam-paper {
      .paper-title {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 800;
      }
      .paper-question {
        border: solid 1px #e0e0e0;
        border-radius: 4px;
        .paper-question-header {
          display: flex;
          align-items: center;
          text-align: center;
          margin: 22px 16px 0 16px;
          .paper-question-num {
            min-width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 13px;
            margin-right: 10px;
            color: #ffffff;
            background-color: #212121;
            border-radius: 50%;
          }
          .paper-question-var-num {
            min-width: 35px;
            height: 24px;
            line-height: 24px;
            font-size: 13px;
            margin-right: 10px;
            color: #ffffff;
            background-color: #0091ea;
            border-radius: 12px;
          }
          .paper-question-type {
            font-size: 14px;
            font-weight: bold;
            margin-right: 10px;
            color: #000000;
          }
          .paper-question-difficulty {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .paper-question-content {
          font-size: 14px;
          margin: 16px 24px;
          overflow-x: auto;
        }
        .paper-question-content.isCollapse {
          max-height: 160px;
          overflow-x: hidden;
          overflow-y: hidden;
        }
        .paper-question-content::-webkit-scrollbar {
          width: 7px;
          height: 7px;
        }
        .paper-question-content::-webkit-scrollbar-thumb {
          border-radius: 7px;
          background: rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
        }
        .paper-question-footer {
          display: flex;
          align-items: center;
          text-align: center;
          height: 40px;
          background-color: #f5f5f5;
          border-top: solid 1px #e0e0e0;
          .paper-question-knowledge {
            display: flex;
            align-items: center;
            text-align: center;
            max-width: 900px;
            overflow: hidden;
            white-space: nowrap;
            margin-left: 24px;
            .paper-question-knowledge-title {
              font-size: 14px;
            }
            .paper-question-knowledge-name {
              font-size: 14px;
              padding: 2px 10px;
              margin: 0 4px;
              border-radius: 4px;
              background-color: #e6e6e4;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .paper-question-action-bar {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-left: auto;
            margin-right: 24px;
            font-size: 14px;
            .paper-question-collapse-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              width: 24px;
              height: 24px;
              margin: 0 10px;
              cursor: pointer;
            }
            .paper-question-action {
              color: #2196f3;
              cursor: pointer;
            }
            .paper-question-action-sepaline {
              margin: 0 10px;
              color: #dddddd;
            }
          }
        }
        .paper-question-below {
          .below-item {
            border-bottom: 1px dashed #dddddd;
            padding: 12px;
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
      .paper-question + .paper-question {
        margin-top: 18px;
      }
    }
    .exam-paper + .exam-paper {
      margin-top: 18px;
    }
  }
  .class-grade-content {
    min-height: 100px;
    padding: 10px;
    border-top: 2px solid rgba(0, 0, 0, 0.05);
    .el-checkbox-group {
      display: inline;
    }
    .create-btns {
      display: flex;
      padding: 18px 0;
      .create-report {
        padding: 10px 16px;
        border-radius: 4px;
        background-color: #2474ed;
        color: #ffffff;
        cursor: pointer;
      }
      & :first-child {
        margin-right: 18px;
      }
    }
  }
  .answer-analysis-popover {
    position: absolute;
    z-index: 999;
    width: 608px;
    height: 240px;
    padding: 28px 26px;
    background-color: #ffffff;
    box-shadow: 0 8px 8px 0 rgb(0 0 0 / 24%), 0 0 8px 0 rgb(0 0 0 / 12%),
      0 0 0 1px rgb(0 0 0 / 8%);
    border-radius: 4px;
    overflow-y: auto;
    font-size: 14px;
    .popover-item {
      display: table;
      .popover-title {
        font-weight: bold;
        float: left;
      }
      .popover-content {
        margin-left: 120px;
      }
    }
    .popover-item + .popover-item {
      margin-top: 24px;
    }
  }
  .answer-analysis-popover::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .answer-analysis-popover::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
  }
}
</style>
