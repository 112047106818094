<!--  -->
<template>
  <div class="class-grade-content">
    <div class="class">
      <span>班级：</span>
      <el-checkbox-group
        v-model="selectedClassesItems"
        @change="showChangeData"
      >
        <el-checkbox v-for="index in allClassItems" :key="index" :label="index">
          {{ index + "班" }}
        </el-checkbox>
      </el-checkbox-group>
      <div class="btns">
        <div class="cancel-select btn" @click="selectClasses(0)">取消选中</div>
        |
        <div class="anti-select btn" @click="selectClasses(1)">反选</div>
        |
        <div class="select-all btn" @click="selectClasses(2)">全选</div>
      </div>
    </div>
    <div class="create-btns">
      <div>
        <el-button class="create-report" @click="previewParamSetting(1)">
          预览班级报告参数设置
        </el-button>
        <el-button class="create-report wrap" @click="previewParamSetting(2)">
          预览年级报告参数设置
        </el-button>
      </div>
      <!-- 班级报告 -->
      <!-- <div class="class-btns"> -->
      <el-button
        v-if="isGenerateClassReportVisible"
        class="create-report"
        :loading="buttonLoadingList[0].loading"
        :disabled="buttonLoadingList[0].disabled"
        @click="createClassOrGradeReport('class-generating', 1, 0)"
      >
        生成班级报告
      </el-button>
      <el-button
        v-if="isGeneratingClassReportVisible"
        class="create-report"
        :loading="buttonLoadingList[2].loading"
        :disabled="buttonLoadingList[2].disabled"
      >
        正在生成班级报告
      </el-button>
      <el-button
        class="create-report"
        :disabled="buttonLoadingList[0].disabled"
        @click="clearReport(1)"
      >
        清除班级报告
      </el-button>
      <el-button
        v-if="isDownloadClassReportVisible"
        class="create-report"
        :loading="buttonLoadingList[3].loading"
        :disabled="buttonLoadingList[3].disabled"
        @click="downloadReport(1)"
      >
        下载班级报告
      </el-button>
      <!-- </div> -->
      <!-- 年级报告 -->
      <!-- <div class="grade-btns"> -->
      <el-button
        v-if="isGenerateGradeReportVisible"
        class="create-report"
        :loading="buttonLoadingList[4].loading"
        :disabled="buttonLoadingList[4].disabled"
        @click="createClassOrGradeReport('grade-generating', 2, 0)"
      >
        生成年级报告
      </el-button>
      <el-button
        v-if="isGeneratingGradeReportVisible"
        class="create-report"
        :loading="buttonLoadingList[6].loading"
        :disabled="buttonLoadingList[6].disabled"
      >
        正在生成年级报告
      </el-button>
      <el-button
        class="create-report"
        :disabled="buttonLoadingList[4].disabled"
        @click="clearReport(2)"
      >
        清除年级报告
      </el-button>
      <el-button
        v-if="isDownloadGradeReportVisible"
        class="create-report"
        :loading="buttonLoadingList[7].loading"
        :disabled="buttonLoadingList[7].disabled"
        @click="downloadReport(2)"
      >
        下载年级报告
      </el-button>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { fileDownloadByUrl } from "@/core/util/util";
import {
  generate,
  getReportUrl,
  clearReportUrl,
} from "@/core/api/analy/analyreportsubject";
//
export default {
  props: {
    classAndGradeData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      //班级/年级报告
      selectedClassesItems: [],
      allClassItems: [],
      reportIdNum: [], //报告的参数设置模板id 0-班级报告 1-年级报告

      //报告生成按钮组--禁用，加载
      buttonLoadingList: [
        {
          loading: false,
          disabled: false,
          name: "class-generate",
        },
        {
          loading: false,
          disabled: false,
          name: "class-regenerate",
        },
        {
          loading: false,
          disabled: false,
          name: "class-generating",
        },
        {
          loading: false,
          disabled: false,
          name: "class-download",
        },

        {
          loading: false,
          disabled: false,
          name: "grade-generate",
        },
        {
          loading: false,
          disabled: false,
          name: "grade-regenerate",
        },
        {
          loading: false,
          disabled: false,
          name: "grade-generating",
        },
        {
          loading: false,
          disabled: false,
          name: "grade-download",
        },
      ],
      //报告生成按钮组--显示
      //班级报告
      isGenerateClassReportVisible: false,
      isGeneratingClassReportVisible: false,
      isReGenerateClassReportVisible: false,
      isDownloadClassReportVisible: false,
      //年级报告
      isGenerateGradeReportVisible: false,
      isGeneratingGradeReportVisible: false,
      isReGenerateGradeReportVisible: false,
      isDownloadGradeReportVisible: false,
    };
  },
  watch: {
    classAndGradeData() {
      this.selectedClassesItems = this.allClassItems;
      this.refreshPageToGetBasicData();
      return this.classAndGradeData;
    },
  },
  mounted() {
    this.refreshPageToGetBasicData();
  },
  methods: {
    selectClasses(val) {
      if (val == 2) {
        //全选
        this.selectedClassesItems = this.allClassItems;
      }
      if (val == 1) {
        //反选
        let arr = [];
        this.allClassItems.forEach((item) => {
          if (this.selectedClassesItems.indexOf(item) == -1) {
            arr.push(item);
          }
        });
        this.selectedClassesItems = arr;
      }
      if (val == 0) {
        //取消选中
        this.selectedClassesItems = [];
      }
    },
    clearReport(val) {
      //
      this.$confirm(
        val == 1 ? "是否清空班级报告?" : "是否清空年级报告",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let data = {
            reportSubjectId: this.$route.query.id,
          };
          clearReportUrl(data, val).then(() => {
            this.$message({
              type: "success",
              message: "清空成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    showChangeData() {
      // console.log(this.selectedClassesItems);
    },
    //刷新页面数据
    refreshPageToGetBasicData() {
      //班级数据
      if (
        this.classAndGradeData.classNames &&
        this.classAndGradeData.classNames.indexOf(",") > -1
      ) {
        this.allClassItems = this.classAndGradeData.classNames.split(",");
      } else {
        this.allClassItems = [this.classAndGradeData.classNames];
      }
      this.selectedClassesItems = this.allClassItems;
      // console.log(this.allClassItems);
      (this.reportIdNum = [
        this.classAndGradeData.setting1,
        this.classAndGradeData.setting2,
      ]),
        // 初始化报告按钮状态
        this.refreshReportButtonVisible();
    },
    //更新按钮组状态--显示
    refreshReportButtonVisible() {
      // 班级报告相关
      this.isGenerateClassReportVisible = false;
      this.isReGenerateClassReportVisible = false;
      this.isGeneratingClassReportVisible = false;
      this.isDownloadClassReportVisible = false;
      let classReportURL = this.classAndGradeData.classtUrl;
      if (classReportURL && classReportURL == 1) {
        this.isGeneratingClassReportVisible = true;
        this.startGetReportUrl(1, 0, 0);
      } else if (classReportURL && classReportURL.indexOf("http") == 0) {
        this.isDownloadClassReportVisible = true;
        this.isReGenerateClassReportVisible = true;
      } else {
        this.isGenerateClassReportVisible = true;
      }
      // 年级报告相关
      this.isGenerateGradeReportVisible = false;
      this.isReGenerateGradeReportVisible = false;
      this.isGeneratingGradeReportVisible = false;
      this.isDownloadGradeReportVisible = false;
      let gradeReportURL = this.classAndGradeData.gradeUrl;
      if (gradeReportURL && gradeReportURL == 1) {
        this.isGeneratingGradeReportVisible = true;
        this.startGetReportUrl(2, 0, 0);
      } else if (gradeReportURL && gradeReportURL.indexOf("http") == 0) {
        this.isDownloadGradeReportVisible = true;
        this.isReGenerateGradeReportVisible = true;
      } else {
        this.isGenerateGradeReportVisible = true;
      }
    },
    //再次更新按钮组状态--加载/禁用
    refreshReportBtnsLoadingAndDisabled(name) {
      this.buttonLoadingList.map((item) => {
        item.disabled = true; //所有按钮禁用
        item.loading = false;
        if (item.name == name) {
          item.disabled = false;
          item.loading = true;
        }
        if (!name) {
          item.disabled = false;
          item.loading = false;
        }
      });
    },
    /**
     * 参数预览/analy/editReport
     * id: 报告id
     * reportIdNum: 参数模板id
     * type: 报告类型
     * isDefault=0
     */
    previewParamSetting(type) {
      this.$store.commit("delKeepArr");
      let _paramId = 0;
      if (this.reportIdNum[type - 1] && this.reportIdNum[type - 1] > 0) {
        _paramId = this.reportIdNum[type - 1];
        this.$router.push({
          name: "EditReport",
          query: {
            id: this.$route.query.id,
            reportIdNum: _paramId,
            type: type,
            isDefault: 0,
          },
        });
      } else {
        this.$message.error("该报告科目未设置相关报告类型的参数");
      }
    },
    /**
     * 生成班级/年级报告
     * name: 按钮标识 -preview:预览 -
     * type: 1-班级报告 2-年级报告
     * isPreview: 0-生成 1-预览(班级/年级报告没有预览)
     */
    createClassOrGradeReport(name, type, preview) {
      //type: 1-班级报告 2-年级报告
      if (type == 2) {
        this.generateReport(name, type, preview);
      } else {
        if (this.selectedClassesItems && this.selectedClassesItems.length > 0) {
          this.generateReport(name, type, preview);
        } else {
          this.$message.error("当前没有选择任何班级");
        }
      }
    },
    setButtonStatus(type, name, index) {
      //index: 0-生成 1-下载/重新生成 2-正在生成
      this.refreshReportBtnsLoadingAndDisabled(name);
      if (type == 1) {
        if (index == 0) {
          this.isGenerateClassReportVisible = true;
          this.isReGenerateClassReportVisible = false;
          this.isGeneratingClassReportVisible = false;
          this.isDownloadClassReportVisible = false;
        }
        if (index == 1) {
          this.isGenerateClassReportVisible = false;
          this.isReGenerateClassReportVisible = true;
          this.isGeneratingClassReportVisible = false;
          this.isDownloadClassReportVisible = true;
        }
        if (index == 2) {
          this.isGenerateClassReportVisible = false;
          this.isReGenerateClassReportVisible = false;
          this.isGeneratingClassReportVisible = true;
          this.isDownloadClassReportVisible = false;
        }
      } else {
        if (index == 0) {
          this.isGenerateGradeReportVisible = true;
          this.isReGenerateGradeReportVisible = false;
          this.isGeneratingGradeReportVisible = false;
          this.isDownloadGradeReportVisible = false;
        }
        if (index == 1) {
          this.isGenerateGradeReportVisible = false;
          this.isReGenerateGradeReportVisible = true;
          this.isGeneratingGradeReportVisible = false;
          this.isDownloadGradeReportVisible = true;
        }
        if (index == 2) {
          this.isGenerateGradeReportVisible = false;
          this.isReGenerateGradeReportVisible = false;
          this.isGeneratingGradeReportVisible = true;
          this.isDownloadGradeReportVisible = false;
        }
      }
    },
    // 生成班级/年级报告报告
    generateReport(name, type, isPreview) {
      this.setButtonStatus(type, name, 2);
      let classIds = this.selectedClassesItems.toString();
      let _pages = 1;
      if (type == 2) {
        _pages = 0;
      }
      generate(
        Object.assign({
          isPreview: 0,
          reportType: type || 0,
          reportSubjectId: this.$route.query.id,
          classNums: classIds,
          countPages: _pages,
        })
      )
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.data) {
              if (res.data.data.indexOf("http") == 0) {
                this.setButtonStatus(type, "", 0);
                fileDownloadByUrl(res.data.data);
              } else {
                this.setButtonStatus(type, name, 2);
                this.startGetReportUrl(type, isPreview);
              }
            } else {
              // this.$message.error("生成报告失败...");
            }
          } else {
            // this.$message.error("生成报告失败了");
          }
        })
        .catch(() => {
          this.setButtonStatus(type, "", 0);
          this.refreshReportBtnsLoadingAndDisabled();
        });
    },
    startGetReportUrl(type, isPreview) {
      let thisObj = this;
      setTimeout(function () {
        thisObj.sendGetReportUrl(type, isPreview);
      }, 3000);
    },
    sendGetReportUrl(type, isPreview) {
      let classIds = this.selectedClassesItems.toString();
      let _pages = 1;
      if (type == 2) {
        _pages = 0;
      }
      let getURLQuery = {
        reportSubjectId: this.$route.query.id,
        reportType: type,
        isPreview: isPreview,
        classNums: classIds,
        countPages: _pages,
      };
      getReportUrl(getURLQuery)
        .then((data) => {
          let currentUrl = data.data.data;
          if (currentUrl == "") {
            //进度比展示，表示生成途中，轮询
            if (type == 1) {
              this.setButtonStatus(type, "class-generating", 2);
            } else {
              this.setButtonStatus(type, "grade-generating", 2);
            }
            this.startGetReportUrl(type, isPreview);
          } else if (currentUrl.length > 0) {
            if (currentUrl == 1) {
              // if (type == 1) {
              //   this.setButtonStatus(type, "class-generating", 2);
              // } else {
              //   this.setButtonStatus(type, "grade-generating", 2);
              // }
              // this.startGetReportUrl(type, isPreview);
            } else if (currentUrl.indexOf("http") == 0) {
              //http开头,报告生成成功
              this.setButtonStatus(type, "", 0);
              fileDownloadByUrl(currentUrl);
            } else if (currentUrl.indexOf("/") > -1) {
              //进度比展示，表示生成途中，轮询
              if (type == 1) {
                this.setButtonStatus(type, "class-generating", 2);
              } else {
                this.setButtonStatus(type, "grade-generating", 2);
              }
              this.startGetReportUrl(type, isPreview);
            } else {
              // this.$message.error("报告生成失败了...");
              this.setButtonStatus(type, "", 0);
            }
          } else {
            if (type == 2) {
              this.setButtonStatus(type, "grade-generating", 2);
              this.startGetReportUrl(type, isPreview);
            } else {
              // this.$message.error("报告生成失败了...");
              this.setButtonStatus(type, "", 0);
            }
          }
        })
        .catch(() => {
          // this.$message.error(err);
          this.setButtonStatus(type, "", 0);
        });
    },
    downloadReport(type) {
      if (type == 1) {
        if (
          this.currentReportSubject.classUrl &&
          this.currentReportSubject.classUrl.indexOf("http") == 0
        ) {
          fileDownloadByUrl(this.currentReportSubject.classUrl);
        }
        // 如果无法下载，说明有问题，刷新按钮
        else {
          this.refreshReportButtonVisible();
        }
      } else if (type == 2) {
        if (
          this.currentReportSubject.gradeUrl &&
          this.currentReportSubject.gradeUrl.indexOf("http") == 0
        ) {
          fileDownloadByUrl(this.currentReportSubject.gradeUrl);
        }
        // 如果无法下载，说明有问题，刷新按钮
        else {
          this.refreshReportButtonVisible();
        }
      }
    },
  },
};
</script>
<style lang="scss">
.class-grade-content {
  min-height: 100px;
  padding: 10px;
  border-top: 2px solid rgba(0, 0, 0, 0.05);
  .class {
    .btns {
      padding: 10px 0;
      display: flex;
      flex-direction: row-reverse;
      .btn {
        padding: 0 10px;
        color: #409eff;
        cursor: pointer;
      }
    }
  }
  .el-checkbox-group {
    display: inline;
  }
  .create-btns {
    .create-report {
      padding: 10px 16px;
      border-radius: 4px;
      background-color: #2474ed;
      color: #ffffff;
      cursor: pointer;
      margin: 10px;
      i {
        color: #ffffff;
      }
    }
  }
}
</style>
